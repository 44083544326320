<template>
  <div class="home">
    <CustomHeader></CustomHeader>
    <div class="banner-bg">
      <div class="slogan">
        <p class="p1">USE SCENARIOS</p>
        <p class="p2">无人机多行业应用</p>
      </div>
      <div class="banner-title opcity">
        <div class="core">
          <div @click="view('#petroleum')">石油化工</div>
          <div @click="view('#communication')">通信行业</div>
          <div @click="view('#energy')">新型能源</div>
          <div @click="view('#agriculture')">农业植保</div>
          <div @click="view('#forestry')">林业保护</div>
        </div>
      </div>
    </div>
    <!-- 用途场景 -->
    <div class="bg-c">
      <div class="pt-8">
        <h1 class="h1" data-aos="fade-down">用途场景</h1>
        <Title back="#eff5ff" title="Application Scenario"></Title>
      </div>
      <!-- 视频 -->
      <div class="video">
        <video v-if="viewFlag" class="opcity" ref="videoPlayer" webkit-playsinline playsinline x5-playsinline muted autoplay loop src="https://cdn.aihangtec.com/index/video/uav.mp4"></video>
        <img class="unopcity" src="https://cdn.aihangtec.com/index/video/uav.gif" />
      </div>
    </div>
    <!-- 用途场景 -->
    <div class="bg-w p-6" id="petroleum">
      <div class="core card1">
        <div class="card-img">
          <img class="opcity" src="https://cdn.aihangtec.com/index/image/plan/u1.png" />
          <img class="unopcity" src="https://cdn.aihangtec.com/index/image/plan/mu1.png" />
        </div>
        <div class="card-inner">
          <div class="card-title">
            <div class="card-num" data-aos="flip-left">01</div>
            <div class="line"></div>
            <div>石油化工</div>
          </div>
          <div class="card-text">
            通过长距离巡检无人机对石油化工行业野外输油、输气管线进行安全巡查智能检测，利用神经网络算法和图像视别技术自动分析和管理海量无人机巡检数据，对重点管道设施周边高风险区、高后果区和地质灾害高发地区进行精准的航空测绘，为制定管道安全应急预案和发生事故时的现场处置提供更精确的地理信息支持。
          </div>
          <div class="card-type" data-aos="fade-right">
            <div>· 管道巡检</div>
            <div>· 隐患排查</div>
            <div>· 气体泄漏</div>
            <div>· 装置检查</div>
            <div>· 现场侦察</div>
            <div>· 灵活调度</div>
            <div>· 地理测绘</div>
            <div>· 管道规划</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-w p-6" id="communication">
      <div class="core card1">
        <div class="card-inner">
          <div class="right">
            <div class="card-title">
              <div class="card-num" data-aos="flip-left">02</div>
              <div class="line"></div>
              <div>通信行业</div>
            </div>
            <div class="card-text">
              结合蜂窝网络，无人机能够以低成本、高移动性的方式进行数据传输，并实现远程操控功能，同时也为建立新的专用无人机网络提供了可能，无人机可作为空中信号中级基站，帮助地面宏基站为无信号区域提供高速率的数据覆盖。
            </div>
            <div class="card-type" data-aos="fade-left">
              <div>· 基站巡检</div>
              <div>· 线路巡检</div>
              <div>· 通信基站</div>
              <div>· 网络优化</div>
            </div>
          </div>
        </div>
        <div class="card-img">
          <img class="opcity" src="https://cdn.aihangtec.com/index/image/plan/u2.png" />
          <img class="unopcity" src="https://cdn.aihangtec.com/index/image/plan/mu2.png" />
        </div>
      </div>
    </div>
    <div class="bg-w p-6" id="energy">
      <div class="core card1">
        <div class="card-img">
          <img class="opcity" src="https://cdn.aihangtec.com/index/image/plan/u3.png" />
          <img class="unopcity" src="https://cdn.aihangtec.com/index/image/plan/mu3.png" />
        </div>
        <div class="card-inner">
          <div class="card-title">
            <div class="card-num" data-aos="flip-left">03</div>
            <div class="line"></div>
            <div>新型能源</div>
          </div>
          <div class="card-text">
            无人机搭载可见光相机、热红外传感器或EL检测设备，采集光伏组件的可见光、热红外图像或EL数据，提高光伏巡检效率和安全性。可见光和热红外图像实时存储，后续利用无人机智能诊断软件对无人机采集的热红外图像和可见光图像进行智能化处理，实现组件不发电检测、灰尘污垢遮挡、组件裂纹破损等故障引起的热斑自动化诊断和定位。
          </div>
          <div class="card-type" data-aos="fade-right">
            <div>· 勘察选址</div>
            <div>· 勘测设计</div>
            <div>· 正射影像</div>
            <div>· 热斑巡检</div>
            <div>· 故障定位</div>
            <div>· 热红外巡检</div>
            <div class="opcity">· 前端识别缺陷巡检</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-w p-6" id="agriculture">
      <div class="core card1">
        <div class="card-inner">
          <div class="right">
            <div class="card-title">
              <div class="card-num" data-aos="flip-left">04</div>
              <div class="line"></div>
              <div>农业植保</div>
            </div>
            <div class="card-text">
              为农户、农场与农企提供科学的生产管理解决方案，专业农业无人机对片区农情进行数据采集，对于样本数据作出验证，并将采集数据回传至农情管理平台分析输出报告，和植保作业前农作物生长状况以及病虫害等问题进行对比。匹配作物生长关键指标，精准管理农事生产规划与执行。
            </div>
            <div class="card-type" data-aos="fade-left">
              <div>· 智能测绘</div>
              <div>· 农药精准喷洒</div>
              <div class="opcity">· 农作物生长检测</div>
              <div>· 病虫害监测</div>
              <div>· 土壤湿度监测</div>
              <div class="opcity">· 规范化农事记录</div>
              <div>· 执行农事生产</div>
            </div>
          </div>
        </div>
        <div class="card-img">
          <img class="opcity" src="https://cdn.aihangtec.com/index/image/plan/u4.png" />
          <img class="unopcity" src="https://cdn.aihangtec.com/index/image/plan/mu4.png" />
        </div>
      </div>
    </div>
    <div class="bg-w p-6" id="forestry">
      <div class="core card1">
        <div class="card-img">
          <img class="opcity" src="https://cdn.aihangtec.com/index/image/plan/u5.png" />
          <img class="unopcity" src="https://cdn.aihangtec.com/index/image/plan/mu5.png" />
        </div>
        <div class="card-inner">
          <div class="card-title">
            <div class="card-num" data-aos="flip-left">05</div>
            <div class="line"></div>
            <div>林业保护</div>
          </div>
          <div class="card-text">
            无人机搭载多功能吊舱开展常态化巡检不仅提升森林防火观测巡查的时效性和覆盖广度，提高工作效率，降低林业部门费用支出和管理成本，同时对森林资源、生态环境、森林病虫害及野生动物等进行有效的巡查监测和保护工作。
          </div>
          <div class="card-type" data-aos="fade-right">
            <div>· 林草资源检测</div>
            <div>· 野生动物监测</div>
            <div>· 荒漠化沙化调查</div>
            <div>· 林木精准定位</div>
            <div>· 林区防火救灾</div>
            <div class="opcity">· 盗伐滥伐林木调查</div>
            <div class="opcity">· 违法征占林地调查</div>
            <div>· 毁林开荒调查</div>
            <!-- <div>· 远程灾情查看处理</div>
            <div>· 荒漠化沙化调查</div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 平台特点 -->
    <div class="main pt-8">
      <h1 class="h1" data-aos="fade-up">平台特点</h1>
      <Title title="Platform characteristics"></Title>
      <div class="core list-wrap mt-2">
        <div class="list">
          <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/x1.png" /></div>
          <div>
            <p class="title">管线隐患排查</p>
          </div>
        </div>
        <div class="list">
          <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/x2.png" /></div>
          <div>
            <p class="title">倾斜摄影</p>
          </div>
        </div>
        <div class="list">
          <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/x3.png" /></div>
          <div>
            <p class="title">热斑检测</p>
          </div>
        </div>
        <div class="list">
          <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/x4.png" /></div>
          <div>
            <p class="title">高温监测</p>
          </div>
        </div>
        <div class="list">
          <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/x5.png" /></div>
          <div>
            <p class="title">农作物生长检测</p>
          </div>
        </div>
        <div class="list">
          <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/x6.png" /></div>
          <div>
            <p class="title">病虫害监测</p>
          </div>
        </div>
        <div class="list">
          <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/x7.png" /></div>
          <div>
            <p class="title">农药精准喷洒</p>
          </div>
        </div>
        <div class="list">
          <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/x8.png" /></div>
          <div>
            <p class="title">林区防火救灾</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 客户案例 -->
    <div class="pt-8 mb-8">
      <h1 class="h1" data-aos="fade-up">客户认可</h1>
      <Title title="Customer Recognition"></Title>
      <CustomSwiper />
    </div>
    <Fixed class="opcity"></Fixed>
    <CustomFooter></CustomFooter>
  </div>
</template>

<script>
import AOS from "aos"
import { scrollInto } from "../../utils/util"
export default {
  data() {
    return {
      viewFlag: true
    }
  },
  mounted() {
    AOS.init()
    if (document.body.clientWidth < 780) {
      this.viewFlag = false
    } else {
      scrollInto()
    }
  },
  methods: {
    view(link) {
      scrollInto(link)
    },
    custom() {
      this.$router.push("/custom")
      document.documentElement.scrollTop = 0
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../style/plan.scss";
.banner-bg {
  background-image: url(https://cdn.aihangtec.com/index/image/plan/banner_uav.png);
}
.card-type {
  @media screen and (min-width: 540px) {
    bottom: 30px !important;
  }
  & > div {
    @media screen and (min-width: 540px) {
      width: 25% !important;
      margin-top: 24px;
    }
  }
}
</style>
